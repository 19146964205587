/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v25-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-600 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/nunito-v25-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v25-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v25-latin-600.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-700 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunito-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v25-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v25-latin-700.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* livvic-100 - latin */
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/livvic-v13-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-100.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-100italic - latin */
@font-face {
  font-family: 'Livvic';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/livvic-v13-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-100italic.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-200 - latin */
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/livvic-v13-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-200.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-200italic - latin */
@font-face {
  font-family: 'Livvic';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/livvic-v13-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-200italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-200italic.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-300 - latin */
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/livvic-v13-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-300.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-300italic - latin */
@font-face {
  font-family: 'Livvic';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/livvic-v13-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-300italic.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-regular - latin */
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/livvic-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-regular.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-italic - latin */
@font-face {
  font-family: 'Livvic';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/livvic-v13-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-italic.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-500 - latin */
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/livvic-v13-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-500.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-500italic - latin */
@font-face {
  font-family: 'Livvic';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/livvic-v13-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-500italic.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-600 - latin */
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/livvic-v13-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-600.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-600italic - latin */
@font-face {
  font-family: 'Livvic';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/livvic-v13-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-600italic.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-700 - latin */
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/livvic-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-700.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-700italic - latin */
@font-face {
  font-family: 'Livvic';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/livvic-v13-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-700italic.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-900 - latin */
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/livvic-v13-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-900.svg#Livvic') format('svg'); /* Legacy iOS */
}

/* livvic-900italic - latin */
@font-face {
  font-family: 'Livvic';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/livvic-v13-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/livvic-v13-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/livvic-v13-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/livvic-v13-latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/livvic-v13-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/livvic-v13-latin-900italic.svg#Livvic') format('svg'); /* Legacy iOS */
}

